//==============================================================================
// Conditional Container
//
// Container module that can be disabled to hide child content.
//==============================================================================
import * as React from 'react';

import { IConditionalContainerProps } from './conditional-container.props.autogenerated';

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ConditionalContainer component
 * @extends {React.PureComponent<IConditionalContainerProps<{}>>}
 */
//==============================================================================
class ConditionalContainer extends React.PureComponent<IConditionalContainerProps<{}>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        return this._displayContent();
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _displayContent(): JSX.Element | null {
        const { config, slots } = this.props;
        const hasSlots = slots && slots.contentBlock && slots.contentBlock.length;

        const isEnabled = (config.enabled && this._isEnabled(config.startDate, config.endDate));

        if (isEnabled && hasSlots) {
            return (
                <React.Fragment>
                    {this._renderSlotItems(slots.contentBlock)}
                </React.Fragment>
            );
        }

        // No slots, so there's nothing to do
        return null;
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderSlotItems(items: React.ReactNode[]): JSX.Element {
        return (
            <React.Fragment>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }

    //------------------------------------------------------
    // Determine whether the current date is between the start
    // and end dates.
    //
    // Start and end dates are optional, so only check if they
    // are present and valid.
    //------------------------------------------------------
    private _isEnabled(startDate: string | undefined, endDate: string | undefined): boolean {
        const now = Date.now();

        // Check to see if we should disable based on the start date
        const start = startDate && new Date(startDate).getTime();
        if (start && !isNaN(start)) {
            if (now < start) {
                return false;
            }
        }

        // Check to see if we should disable based on the end date
        const end = endDate && new Date(endDate).getTime();
        if (end && !isNaN(end)) {
            const buffer = 1000 * 60 * 60 * 24;     // 24 hours
            if (now > (end + buffer)) {
                return false;
            }
        }

        // We failed to fail out. Success!
        return true;
    }
}

export default ConditionalContainer;
